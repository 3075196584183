import * as React from "react"

const Header = (props) => {
  const page = props.page
  return (
    <div className="mini">
        <div className="mini-content">
            <div className="container content-header">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10 col-md-12 col-sm-12">
                        <h1 className="mb-3">{page.title}</h1>
                        <p>{page.description}</p>
                        <p>{page.date}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header
