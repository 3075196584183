import React from "react";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

const Share = ({ data }) => (
  <div className="mb-3">
    <span className="mr-2">Share:</span>
    <FacebookShareButton
      url={data.url}
      quote={data.title}
      className="mr-2"
    >
      <FacebookIcon size={32} round/>
    </FacebookShareButton>
    <TwitterShareButton
      url={data.url}
      title={data.title}
      className="mr-2"
    >
      <TwitterIcon size={32} round/>
    </TwitterShareButton>
    <LinkedinShareButton
      url={data.url}
      title={data.title}
      summary={data.summary}
      source={data.host}
      className="mr-2"
    >
      <LinkedinIcon size={32} round/>
    </LinkedinShareButton>
    <EmailShareButton
      url={data.url}
      sumbject={data.title}
      body={data.summary}
      className="mr-2"
    >
      <EmailIcon size={32} round/>
    </EmailShareButton>
  </div>
)

Share.propTypes = {
  data: PropTypes.shape({
    host: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired
  })
};

export default Share;