import React from "react"
import Header from "../general/header"
import BlogHeader from "../general/blog-header"
import Footer from "../general/footer"
import JSONData from "../../data/texts.json"

export default function BlogPostLayout({ children, page, classes }) {
  return (
    <>
      <Header menu={JSONData.menu}/>
      <BlogHeader page={page}/>
      <section className="section py-3 content-section" id="content">
        <div className={`container landing-container ${classes}`}>
          {children}
        </div>
      </section>
      <Footer/>
    </>
  )
}